[data-theme="arc-light"] {
  --scheme-1: 249 250 251;
  --scheme-2: 243 244 246;
  --scheme-3: 229 231 235;
  --scheme-4: 209 213 219;
  --scheme-5: 156 163 175;
  --primary-1: 30 58 138;
  --primary-2: 30 64 175;
  --primary-3: 29 78 216;
  --primary-4: 37 99 235;
  --primary-5: 59 130 246;
  --onprimary-1: 255 255 255;
  --onprimary-2: 255 255 255;
  --onprimary-3: 255 255 255;
  --onprimary-4: 255 255 255;
  --onprimary-5: 0 0 0;
  --onscheme-1: 0 0 0;
  --onscheme-2: 0 0 0;
  --onscheme-3: 0 0 0;
  --onscheme-4: 0 0 0;
  --onscheme-5: 0 0 0;
}

[data-theme="arc-light"] .X-Arb a, [data-theme="arc-light"] #homeButton {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity) );
}

[data-theme="arc-light"] #homeButton {
  outline-color: #1d4ed8;
}

[data-theme="arc-light"] #homeButton:hover {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity) );
  outline-color: #3b82f6;
}

[data-theme="arc-light"] .X-MenuBarButton {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity) );
  --tw-text-opacity: 1;
}

[data-theme="arc-light"] .X-MenuBarButton:hover {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity) );
}

[data-theme="arc-light"] .X-Hide-Dark {
  height: 1rem;
  width: 1rem;
  display: inline;
}

[data-theme="arc-dark"] {
  --scheme-1: 15 23 42;
  --scheme-2: 30 41 59;
  --scheme-3: 51 65 85;
  --scheme-4: 71 85 105;
  --scheme-5: 100 116 139;
  --primary-1: 30 58 138;
  --primary-2: 30 64 175;
  --primary-3: 29 78 216;
  --primary-4: 37 99 235;
  --primary-5: 59 130 246;
  --onprimary-1: 255 255 255;
  --onprimary-2: 255 255 255;
  --onprimary-3: 255 255 255;
  --onprimary-4: 255 255 255;
  --onprimary-5: 0 0 0;
  --onscheme-1: 255 255 255;
  --onscheme-2: 255 255 255;
  --onscheme-3: 255 255 255;
  --onscheme-4: 255 255 255;
  --onscheme-5: 255 255 255;
}

[data-theme="arc-dark"] .X-Arb a, [data-theme="arc-dark"] #homeButton {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity) );
}

[data-theme="arc-dark"] #homeButton {
  outline-color: #1d4ed8;
}

[data-theme="arc-dark"] #homeButton:hover {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity) );
  outline-color: #3b82f6;
}

[data-theme="arc-dark"] .X-MenuBarButton {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity) );
  --tw-text-opacity: 1;
}

[data-theme="arc-dark"] .X-MenuBarButton:hover {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity) );
}

[data-theme="arc-dark"] #menubar.nimbus--header-bar {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity) ) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity) ) !important;
}

[data-theme="arc-dark"] .X-Hide-Light {
  height: 1rem;
  width: 1rem;
  display: inline;
}

@media (prefers-color-scheme: dark) {
  [data-theme="arc-auto"] {
    --scheme-1: 15 23 42;
    --scheme-2: 30 41 59;
    --scheme-3: 51 65 85;
    --scheme-4: 71 85 105;
    --scheme-5: 100 116 139;
    --primary-1: 30 58 138;
    --primary-2: 30 64 175;
    --primary-3: 29 78 216;
    --primary-4: 37 99 235;
    --primary-5: 59 130 246;
    --onprimary-1: 255 255 255;
    --onprimary-2: 255 255 255;
    --onprimary-3: 255 255 255;
    --onprimary-4: 255 255 255;
    --onprimary-5: 0 0 0;
    --onscheme-1: 255 255 255;
    --onscheme-2: 255 255 255;
    --onscheme-3: 255 255 255;
    --onscheme-4: 255 255 255;
    --onscheme-5: 255 255 255;
  }

  [data-theme="arc-auto"] .X-Arb a, [data-theme="arc-auto"] #homeButton {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity) );
  }

  [data-theme="arc-auto"] #homeButton {
    outline-color: #1d4ed8;
  }

  [data-theme="arc-auto"] #homeButton:hover {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity) );
    outline-color: #3b82f6;
  }

  [data-theme="arc-auto"] .X-MenuBarButton {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity) );
    --tw-text-opacity: 1;
  }

  [data-theme="arc-auto"] .X-MenuBarButton:hover {
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity) );
  }

  [data-theme="arc-auto"] #menubar.nimbus--header-bar {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity) ) !important;
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity) ) !important;
  }

  [data-theme="arc-auto"] .X-Hide-Light {
    height: 1rem;
    width: 1rem;
    display: inline;
  }
}

@media not (prefers-color-scheme: dark) {
  [data-theme="arc-auto"] {
    --scheme-1: 249 250 251;
    --scheme-2: 243 244 246;
    --scheme-3: 229 231 235;
    --scheme-4: 209 213 219;
    --scheme-5: 156 163 175;
    --primary-1: 30 58 138;
    --primary-2: 30 64 175;
    --primary-3: 29 78 216;
    --primary-4: 37 99 235;
    --primary-5: 59 130 246;
    --onprimary-1: 255 255 255;
    --onprimary-2: 255 255 255;
    --onprimary-3: 255 255 255;
    --onprimary-4: 255 255 255;
    --onprimary-5: 0 0 0;
    --onscheme-1: 0 0 0;
    --onscheme-2: 0 0 0;
    --onscheme-3: 0 0 0;
    --onscheme-4: 0 0 0;
    --onscheme-5: 0 0 0;
  }

  [data-theme="arc-auto"] .X-Arb a, [data-theme="arc-auto"] #homeButton {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity) );
  }

  [data-theme="arc-auto"] #homeButton {
    outline-color: #1d4ed8;
  }

  [data-theme="arc-auto"] #homeButton:hover {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity) );
    outline-color: #3b82f6;
  }

  [data-theme="arc-auto"] .X-MenuBarButton {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity) );
    --tw-text-opacity: 1;
  }

  [data-theme="arc-auto"] .X-MenuBarButton:hover {
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity) );
  }

  [data-theme="arc-auto"] .X-Hide-Dark {
    height: 1rem;
    width: 1rem;
    display: inline;
  }
}

[data-theme="chaos-light"] {
  --scheme-1: 255 255 255;
  --scheme-2: 242 243 245;
  --scheme-3: 227 229 232;
  --scheme-4: 220 221 222;
  --scheme-5: 106 116 128;
  --primary-1: 146 147 255;
  --primary-2: 94 102 204;
  --primary-3: 88 101 242;
  --primary-4: 91 101 242;
  --primary-5: 0 60 189;
  --onprimary-1: 255 255 255;
  --onprimary-2: 255 255 255;
  --onprimary-3: 255 255 255;
  --onprimary-4: 255 255 255;
  --onprimary-5: 0 0 0;
  --onscheme-1: 0 0 0;
  --onscheme-2: 0 0 0;
  --onscheme-3: 0 0 0;
  --onscheme-4: 0 0 0;
  --onscheme-5: 0 0 0;
}

[data-theme="chaos-light"] .X-Arb a {
  --tw-text-opacity: 1;
  color: rgb(0 175 244 / var(--tw-text-opacity) );
}

[data-theme="chaos-light"] .X-MenuBarButton {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity) );
  --tw-text-opacity: 1;
}

[data-theme="chaos-light"] .X-MenuBarButton:hover {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity) );
}

[data-theme="chaos-light"] .X-Hide-Light {
  height: 1rem;
  width: 1rem;
  display: inline;
}

[data-theme="chaos-light"] .X-Hide-Dark {
  height: 1rem;
  width: 1rem;
  display: inline;
}

[data-theme="chaos-dark"] {
  --scheme-1: 24 25 28;
  --scheme-2: 32 34 37;
  --scheme-3: 47 49 54;
  --scheme-4: 54 57 63;
  --scheme-5: 79 84 92;
  --primary-1: 146 147 255;
  --primary-2: 94 102 204;
  --primary-3: 88 101 242;
  --primary-4: 91 101 242;
  --primary-5: 0 60 189;
  --onprimary-1: 255 255 255;
  --onprimary-2: 255 255 255;
  --onprimary-3: 255 255 255;
  --onprimary-4: 255 255 255;
  --onprimary-5: 0 0 0;
  --onscheme-1: 220 221 222;
  --onscheme-2: 220 221 222;
  --onscheme-3: 220 221 222;
  --onscheme-4: 220 221 222;
  --onscheme-5: 220 221 222;
}

[data-theme="chaos-dark"] .X-Arb a {
  --tw-text-opacity: 1;
  color: rgb(0 175 244 / var(--tw-text-opacity) );
}

[data-theme="chaos-dark"] .X-MenuBarButton {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity) );
  --tw-text-opacity: 1;
}

[data-theme="chaos-dark"] .X-MenuBarButton:hover {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity) );
}

[data-theme="chaos-dark"] .X-Hide-Light {
  height: 1rem;
  width: 1rem;
  display: inline;
}

[data-theme="chaos-dark"] #menubar.nimbus--header-bar {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(32 34 37 / var(--tw-bg-opacity) ) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity) ) !important;
}

[data-theme="chaos-dark"] .X-Hide-Light {
  height: 1rem;
  width: 1rem;
  display: inline;
}

@media (prefers-color-scheme: dark) {
  [data-theme="chaos-auto"] {
    --scheme-1: 24 25 28;
    --scheme-2: 32 34 37;
    --scheme-3: 47 49 54;
    --scheme-4: 54 57 63;
    --scheme-5: 79 84 92;
    --primary-1: 146 147 255;
    --primary-2: 94 102 204;
    --primary-3: 88 101 242;
    --primary-4: 91 101 242;
    --primary-5: 0 60 189;
    --onprimary-1: 255 255 255;
    --onprimary-2: 255 255 255;
    --onprimary-3: 255 255 255;
    --onprimary-4: 255 255 255;
    --onprimary-5: 0 0 0;
    --onscheme-1: 220 221 222;
    --onscheme-2: 220 221 222;
    --onscheme-3: 220 221 222;
    --onscheme-4: 220 221 222;
    --onscheme-5: 220 221 222;
  }

  [data-theme="chaos-auto"] .X-Arb a {
    --tw-text-opacity: 1;
    color: rgb(0 175 244 / var(--tw-text-opacity) );
  }

  [data-theme="chaos-auto"] .X-MenuBarButton {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity) );
    --tw-text-opacity: 1;
  }

  [data-theme="chaos-auto"] .X-MenuBarButton:hover {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity) );
  }

  [data-theme="chaos-auto"] .X-Hide-Light {
    height: 1rem;
    width: 1rem;
    display: inline;
  }

  [data-theme="chaos-auto"] #menubar.nimbus--header-bar {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(32 34 37 / var(--tw-bg-opacity) ) !important;
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity) ) !important;
  }

  [data-theme="chaos-auto"] .X-Hide-Light {
    height: 1rem;
    width: 1rem;
    display: inline;
  }
}

@media not (prefers-color-scheme: dark) {
  [data-theme="chaos-auto"] {
    --scheme-1: 255 255 255;
    --scheme-2: 242 243 245;
    --scheme-3: 227 229 232;
    --scheme-4: 220 221 222;
    --scheme-5: 106 116 128;
    --primary-1: 146 147 255;
    --primary-2: 94 102 204;
    --primary-3: 88 101 242;
    --primary-4: 91 101 242;
    --primary-5: 0 60 189;
    --onprimary-1: 255 255 255;
    --onprimary-2: 255 255 255;
    --onprimary-3: 255 255 255;
    --onprimary-4: 255 255 255;
    --onprimary-5: 0 0 0;
    --onscheme-1: 0 0 0;
    --onscheme-2: 0 0 0;
    --onscheme-3: 0 0 0;
    --onscheme-4: 0 0 0;
    --onscheme-5: 0 0 0;
  }

  [data-theme="chaos-auto"] .X-Arb a {
    --tw-text-opacity: 1;
    color: rgb(0 175 244 / var(--tw-text-opacity) );
  }

  [data-theme="chaos-auto"] .X-MenuBarButton {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity) );
    --tw-text-opacity: 1;
  }

  [data-theme="chaos-auto"] .X-MenuBarButton:hover {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity) );
  }

  [data-theme="chaos-auto"] .X-Hide-Light {
    height: 1rem;
    width: 1rem;
    display: inline;
  }

  [data-theme="chaos-auto"] .X-Hide-Dark {
    height: 1rem;
    width: 1rem;
    display: inline;
  }
}

[data-theme="mint-light"] {
  --scheme-1: 218 242 227;
  --scheme-2: 187 219 199;
  --scheme-3: 158 195 173;
  --scheme-4: 131 171 147;
  --scheme-5: 107 148 123;
  --primary-1: 20 83 45;
  --primary-2: 22 101 52;
  --primary-3: 21 128 61;
  --primary-4: 22 163 74;
  --primary-5: 34 197 94;
  --onprimary-1: 255 255 255;
  --onprimary-2: 255 255 255;
  --onprimary-3: 255 255 255;
  --onprimary-4: 255 255 255;
  --onprimary-5: 0 0 0;
  --onscheme-1: 0 0 0;
  --onscheme-2: 0 0 0;
  --onscheme-3: 0 0 0;
  --onscheme-4: 0 0 0;
  --onscheme-5: 0 0 0;
}

[data-theme="mint-light"] .X-Arb a, [data-theme="mint-light"] #homeButton {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity) );
}

[data-theme="mint-light"] #homeButton {
  outline-color: #16a34a;
}

[data-theme="mint-light"] #homeButton:hover {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity) );
  outline-color: #22c55e;
}

[data-theme="mint-light"] .X-MenuBarButton {
  --tw-text-opacity: 1;
  color: rgb(84 124 100 / var(--tw-text-opacity) );
  --tw-text-opacity: 1;
}

[data-theme="mint-light"] .X-MenuBarButton:hover {
  --tw-text-opacity: 1;
  color: rgb(45 77 58 / var(--tw-text-opacity) );
}

[data-theme="mint-light"] .X-Hide-Dark {
  height: 1rem;
  width: 1rem;
  display: inline;
}

[data-theme="mint-dark"] {
  --scheme-1: 15 30 21;
  --scheme-2: 29 54 39;
  --scheme-3: 45 77 58;
  --scheme-4: 64 101 79;
  --scheme-5: 84 124 100;
  --primary-1: 20 83 45;
  --primary-2: 22 101 52;
  --primary-3: 21 128 61;
  --primary-4: 22 163 74;
  --primary-5: 34 197 94;
  --onprimary-1: 255 255 255;
  --onprimary-2: 255 255 255;
  --onprimary-3: 255 255 255;
  --onprimary-4: 255 255 255;
  --onprimary-5: 0 0 0;
  --onscheme-1: 255 255 255;
  --onscheme-2: 255 255 255;
  --onscheme-3: 255 255 255;
  --onscheme-4: 255 255 255;
  --onscheme-5: 255 255 255;
}

[data-theme="mint-dark"] .X-Arb a, [data-theme="mint-dark"] #homeButton {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity) );
}

[data-theme="mint-dark"] #homeButton {
  outline-color: #16a34a;
}

[data-theme="mint-dark"] #homeButton:hover {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity) );
  outline-color: #22c55e;
}

[data-theme="mint-dark"] .X-MenuBarButton {
  --tw-text-opacity: 1;
  color: rgb(84 124 100 / var(--tw-text-opacity) );
  --tw-text-opacity: 1;
}

[data-theme="mint-dark"] .X-MenuBarButton:hover {
  --tw-text-opacity: 1;
  color: rgb(45 77 58 / var(--tw-text-opacity) );
}

[data-theme="mint-dark"] #menubar.nimbus--header-bar {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(29 54 39 / var(--tw-bg-opacity) ) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity) ) !important;
}

[data-theme="mint-dark"] .X-Hide-Light {
  height: 1rem;
  width: 1rem;
  display: inline;
}

@media (prefers-color-scheme: dark) {
  [data-theme="mint-auto"] {
    --scheme-1: 15 30 21;
    --scheme-2: 29 54 39;
    --scheme-3: 45 77 58;
    --scheme-4: 64 101 79;
    --scheme-5: 84 124 100;
    --primary-1: 20 83 45;
    --primary-2: 22 101 52;
    --primary-3: 21 128 61;
    --primary-4: 22 163 74;
    --primary-5: 34 197 94;
    --onprimary-1: 255 255 255;
    --onprimary-2: 255 255 255;
    --onprimary-3: 255 255 255;
    --onprimary-4: 255 255 255;
    --onprimary-5: 0 0 0;
    --onscheme-1: 255 255 255;
    --onscheme-2: 255 255 255;
    --onscheme-3: 255 255 255;
    --onscheme-4: 255 255 255;
    --onscheme-5: 255 255 255;
  }

  [data-theme="mint-auto"] .X-Arb a, [data-theme="mint-auto"] #homeButton {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity) );
  }

  [data-theme="mint-auto"] #homeButton {
    outline-color: #16a34a;
  }

  [data-theme="mint-auto"] #homeButton:hover {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity) );
    outline-color: #22c55e;
  }

  [data-theme="mint-auto"] .X-MenuBarButton {
    --tw-text-opacity: 1;
    color: rgb(84 124 100 / var(--tw-text-opacity) );
    --tw-text-opacity: 1;
  }

  [data-theme="mint-auto"] .X-MenuBarButton:hover {
    --tw-text-opacity: 1;
    color: rgb(45 77 58 / var(--tw-text-opacity) );
  }

  [data-theme="mint-auto"] #menubar.nimbus--header-bar {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(29 54 39 / var(--tw-bg-opacity) ) !important;
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity) ) !important;
  }

  [data-theme="mint-auto"] .X-Hide-Light {
    height: 1rem;
    width: 1rem;
    display: inline;
  }
}

@media not (prefers-color-scheme: dark) {
  [data-theme="mint-auto"] {
    --scheme-1: 218 242 227;
    --scheme-2: 187 219 199;
    --scheme-3: 158 195 173;
    --scheme-4: 131 171 147;
    --scheme-5: 107 148 123;
    --primary-1: 20 83 45;
    --primary-2: 22 101 52;
    --primary-3: 21 128 61;
    --primary-4: 22 163 74;
    --primary-5: 34 197 94;
    --onprimary-1: 255 255 255;
    --onprimary-2: 255 255 255;
    --onprimary-3: 255 255 255;
    --onprimary-4: 255 255 255;
    --onprimary-5: 0 0 0;
    --onscheme-1: 0 0 0;
    --onscheme-2: 0 0 0;
    --onscheme-3: 0 0 0;
    --onscheme-4: 0 0 0;
    --onscheme-5: 0 0 0;
  }

  [data-theme="mint-auto"] .X-Arb a, [data-theme="mint-auto"] #homeButton {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity) );
  }

  [data-theme="mint-auto"] #homeButton {
    outline-color: #16a34a;
  }

  [data-theme="mint-auto"] #homeButton:hover {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity) );
    outline-color: #22c55e;
  }

  [data-theme="mint-auto"] .X-MenuBarButton {
    --tw-text-opacity: 1;
    color: rgb(84 124 100 / var(--tw-text-opacity) );
    --tw-text-opacity: 1;
  }

  [data-theme="mint-auto"] .X-MenuBarButton:hover {
    --tw-text-opacity: 1;
    color: rgb(45 77 58 / var(--tw-text-opacity) );
  }

  [data-theme="mint-auto"] .X-Hide-Dark {
    height: 1rem;
    width: 1rem;
    display: inline;
  }
}

[data-theme="nimbus-light"] {
  --scheme-1: 245 245 244;
  --scheme-2: 231 229 228;
  --scheme-3: 214 211 209;
  --scheme-4: 168 162 158;
  --scheme-5: 120 113 108;
  --primary-1: 202 138 4;
  --primary-2: 234 179 8;
  --primary-3: 250 204 21;
  --primary-4: 253 224 71;
  --primary-5: 254 240 138;
  --onprimary-1: 0 0 0;
  --onprimary-2: 0 0 0;
  --onprimary-3: 0 0 0;
  --onprimary-4: 0 0 0;
  --onprimary-5: 255 255 255;
  --onscheme-1: 0 0 0;
  --onscheme-2: 0 0 0;
  --onscheme-3: 0 0 0;
  --onscheme-4: 0 0 0;
  --onscheme-5: 255 255 255;
}

[data-theme="nimbus-light"] .X-Arb a {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity) );
}

[data-theme="nimbus-light"] #homeButton {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity) );
  outline-color: #eab308;
}

[data-theme="nimbus-light"] #homeButton:hover {
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity) );
  outline-color: #fde047;
}

[data-theme="nimbus-light"] .X-MenuBarButton {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity) );
  --tw-text-opacity: 1;
}

[data-theme="nimbus-light"] .X-MenuBarButton:hover {
  --tw-text-opacity: 1;
  color: rgb(168 162 158 / var(--tw-text-opacity) );
}

[data-theme="nimbus-light"] .X-Hide-Dark {
  height: 1rem;
  width: 1rem;
  display: inline;
}

[data-theme="nimbus-dark"] {
  --scheme-1: 28 25 23;
  --scheme-2: 41 37 36;
  --scheme-3: 68 64 60;
  --scheme-4: 87 83 78;
  --scheme-5: 120 113 108;
  --primary-1: 202 138 4;
  --primary-2: 234 179 8;
  --primary-3: 250 204 21;
  --primary-4: 253 224 71;
  --primary-5: 254 240 138;
  --onprimary-1: 0 0 0;
  --onprimary-2: 0 0 0;
  --onprimary-3: 0 0 0;
  --onprimary-4: 0 0 0;
  --onprimary-5: 255 255 255;
  --onscheme-1: 255 255 255;
  --onscheme-2: 255 255 255;
  --onscheme-3: 255 255 255;
  --onscheme-4: 255 255 255;
  --onscheme-5: 255 255 255;
}

[data-theme="nimbus-dark"] .X-Arb a {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity) );
}

[data-theme="nimbus-dark"] #homeButton {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity) );
  outline-color: #eab308;
}

[data-theme="nimbus-dark"] #homeButton:hover {
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity) );
  outline-color: #fde047;
}

[data-theme="nimbus-dark"] .X-MenuBarButton {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity) );
  --tw-text-opacity: 1;
}

[data-theme="nimbus-dark"] .X-MenuBarButton:hover {
  --tw-text-opacity: 1;
  color: rgb(168 162 158 / var(--tw-text-opacity) );
}

[data-theme="nimbus-dark"] #menubar.nimbus--header-bar {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(41 37 36 / var(--tw-bg-opacity) ) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity) ) !important;
}

[data-theme="nimbus-dark"] .X-Hide-Light {
  height: 1rem;
  width: 1rem;
  display: inline;
}

@media (prefers-color-scheme: dark) {
  [data-theme="nimbus-auto"] {
    --scheme-1: 28 25 23;
    --scheme-2: 41 37 36;
    --scheme-3: 68 64 60;
    --scheme-4: 87 83 78;
    --scheme-5: 120 113 108;
    --primary-1: 202 138 4;
    --primary-2: 234 179 8;
    --primary-3: 250 204 21;
    --primary-4: 253 224 71;
    --primary-5: 254 240 138;
    --onprimary-1: 0 0 0;
    --onprimary-2: 0 0 0;
    --onprimary-3: 0 0 0;
    --onprimary-4: 0 0 0;
    --onprimary-5: 255 255 255;
    --onscheme-1: 255 255 255;
    --onscheme-2: 255 255 255;
    --onscheme-3: 255 255 255;
    --onscheme-4: 255 255 255;
    --onscheme-5: 255 255 255;
  }

  [data-theme="nimbus-auto"] .X-Arb a {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity) );
  }

  [data-theme="nimbus-auto"] #homeButton {
    --tw-text-opacity: 1;
    color: rgb(234 179 8 / var(--tw-text-opacity) );
    outline-color: #eab308;
  }

  [data-theme="nimbus-auto"] #homeButton:hover {
    --tw-text-opacity: 1;
    color: rgb(253 224 71 / var(--tw-text-opacity) );
    outline-color: #fde047;
  }

  [data-theme="nimbus-auto"] .X-MenuBarButton {
    --tw-text-opacity: 1;
    color: rgb(120 113 108 / var(--tw-text-opacity) );
    --tw-text-opacity: 1;
  }

  [data-theme="nimbus-auto"] .X-MenuBarButton:hover {
    --tw-text-opacity: 1;
    color: rgb(168 162 158 / var(--tw-text-opacity) );
  }

  [data-theme="nimbus-auto"] #menubar.nimbus--header-bar {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(41 37 36 / var(--tw-bg-opacity) ) !important;
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity) ) !important;
  }

  [data-theme="nimbus-auto"] .X-Hide-Light {
    height: 1rem;
    width: 1rem;
    display: inline;
  }
}

@media not (prefers-color-scheme: dark) {
  [data-theme="nimbus-auto"] {
    --scheme-1: 245 245 244;
    --scheme-2: 231 229 228;
    --scheme-3: 214 211 209;
    --scheme-4: 168 162 158;
    --scheme-5: 120 113 108;
    --primary-1: 202 138 4;
    --primary-2: 234 179 8;
    --primary-3: 250 204 21;
    --primary-4: 253 224 71;
    --primary-5: 254 240 138;
    --onprimary-1: 0 0 0;
    --onprimary-2: 0 0 0;
    --onprimary-3: 0 0 0;
    --onprimary-4: 0 0 0;
    --onprimary-5: 255 255 255;
    --onscheme-1: 0 0 0;
    --onscheme-2: 0 0 0;
    --onscheme-3: 0 0 0;
    --onscheme-4: 0 0 0;
    --onscheme-5: 255 255 255;
  }

  [data-theme="nimbus-auto"] .X-Arb a {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity) );
  }

  [data-theme="nimbus-auto"] #homeButton {
    --tw-text-opacity: 1;
    color: rgb(234 179 8 / var(--tw-text-opacity) );
    outline-color: #eab308;
  }

  [data-theme="nimbus-auto"] #homeButton:hover {
    --tw-text-opacity: 1;
    color: rgb(253 224 71 / var(--tw-text-opacity) );
    outline-color: #fde047;
  }

  [data-theme="nimbus-auto"] .X-MenuBarButton {
    --tw-text-opacity: 1;
    color: rgb(120 113 108 / var(--tw-text-opacity) );
    --tw-text-opacity: 1;
  }

  [data-theme="nimbus-auto"] .X-MenuBarButton:hover {
    --tw-text-opacity: 1;
    color: rgb(168 162 158 / var(--tw-text-opacity) );
  }

  [data-theme="nimbus-auto"] .X-Hide-Dark {
    height: 1rem;
    width: 1rem;
    display: inline;
  }
}

[data-theme="unity-light"] {
  --scheme-1: 249 250 251;
  --scheme-2: 243 244 246;
  --scheme-3: 229 231 235;
  --scheme-4: 209 213 219;
  --scheme-5: 156 163 175;
  --primary-1: 154 52 18;
  --primary-2: 194 65 12;
  --primary-3: 234 88 12;
  --primary-4: 249 115 22;
  --primary-5: 251 146 60;
  --onprimary-1: 255 255 255;
  --onprimary-2: 255 255 255;
  --onprimary-3: 255 255 255;
  --onprimary-4: 255 255 255;
  --onprimary-5: 0 0 0;
  --onscheme-1: 0 0 0;
  --onscheme-2: 0 0 0;
  --onscheme-3: 0 0 0;
  --onscheme-4: 0 0 0;
  --onscheme-5: 0 0 0;
}

[data-theme="unity-light"] .X-Arb a, [data-theme="unity-light"] #homeButton {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity) );
}

[data-theme="unity-light"] #homeButton {
  outline-color: #ea580c;
}

[data-theme="unity-light"] #homeButton:hover {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity) );
  outline-color: #fb923c;
}

[data-theme="unity-light"] .X-MenuBarButton {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity) );
  --tw-text-opacity: 1;
}

[data-theme="unity-light"] .X-MenuBarButton:hover {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity) );
}

[data-theme="unity-light"] .X-Hide-Dark {
  height: 1rem;
  width: 1rem;
  display: inline;
}

[data-theme="unity-dark"] {
  --scheme-1: 23 23 23;
  --scheme-2: 41 37 36;
  --scheme-3: 64 64 64;
  --scheme-4: 82 82 82;
  --scheme-5: 115 115 115;
  --primary-1: 154 52 18;
  --primary-2: 194 65 12;
  --primary-3: 234 88 12;
  --primary-4: 249 115 22;
  --primary-5: 251 146 60;
  --onprimary-1: 255 255 255;
  --onprimary-2: 255 255 255;
  --onprimary-3: 255 255 255;
  --onprimary-4: 255 255 255;
  --onprimary-5: 0 0 0;
  --onscheme-1: 255 255 255;
  --onscheme-2: 255 255 255;
  --onscheme-3: 255 255 255;
  --onscheme-4: 255 255 255;
  --onscheme-5: 255 255 255;
}

[data-theme="unity-dark"] .X-Arb a, [data-theme="unity-dark"] #homeButton {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity) );
}

[data-theme="unity-dark"] #homeButton {
  outline-color: #ea580c;
}

[data-theme="unity-dark"] #homeButton:hover {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity) );
  outline-color: #fb923c;
}

[data-theme="unity-dark"] .X-MenuBarButton {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity) );
  --tw-text-opacity: 1;
}

[data-theme="unity-dark"] .X-MenuBarButton:hover {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity) );
}

[data-theme="unity-dark"] #menubar.nimbus--header-bar {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity) ) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity) ) !important;
}

[data-theme="unity-dark"] .X-Hide-Light {
  height: 1rem;
  width: 1rem;
  display: inline;
}

@media (prefers-color-scheme: dark) {
  [data-theme="unity-auto"] {
    --scheme-1: 23 23 23;
    --scheme-2: 41 37 36;
    --scheme-3: 64 64 64;
    --scheme-4: 82 82 82;
    --scheme-5: 115 115 115;
    --primary-1: 154 52 18;
    --primary-2: 194 65 12;
    --primary-3: 234 88 12;
    --primary-4: 249 115 22;
    --primary-5: 251 146 60;
    --onprimary-1: 255 255 255;
    --onprimary-2: 255 255 255;
    --onprimary-3: 255 255 255;
    --onprimary-4: 255 255 255;
    --onprimary-5: 0 0 0;
    --onscheme-1: 255 255 255;
    --onscheme-2: 255 255 255;
    --onscheme-3: 255 255 255;
    --onscheme-4: 255 255 255;
    --onscheme-5: 255 255 255;
  }

  [data-theme="unity-auto"] .X-Arb a, [data-theme="unity-auto"] #homeButton {
    --tw-text-opacity: 1;
    color: rgb(234 88 12 / var(--tw-text-opacity) );
  }

  [data-theme="unity-auto"] #homeButton {
    outline-color: #ea580c;
  }

  [data-theme="unity-auto"] #homeButton:hover {
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity) );
    outline-color: #fb923c;
  }

  [data-theme="unity-auto"] .X-MenuBarButton {
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity) );
    --tw-text-opacity: 1;
  }

  [data-theme="unity-auto"] .X-MenuBarButton:hover {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity) );
  }

  [data-theme="unity-auto"] #menubar.nimbus--header-bar {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity) ) !important;
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity) ) !important;
  }

  [data-theme="unity-auto"] .X-Hide-Light {
    height: 1rem;
    width: 1rem;
    display: inline;
  }
}

@media not (prefers-color-scheme: dark) {
  [data-theme="unity-auto"] {
    --scheme-1: 249 250 251;
    --scheme-2: 243 244 246;
    --scheme-3: 229 231 235;
    --scheme-4: 209 213 219;
    --scheme-5: 156 163 175;
    --primary-1: 154 52 18;
    --primary-2: 194 65 12;
    --primary-3: 234 88 12;
    --primary-4: 249 115 22;
    --primary-5: 251 146 60;
    --onprimary-1: 255 255 255;
    --onprimary-2: 255 255 255;
    --onprimary-3: 255 255 255;
    --onprimary-4: 255 255 255;
    --onprimary-5: 0 0 0;
    --onscheme-1: 0 0 0;
    --onscheme-2: 0 0 0;
    --onscheme-3: 0 0 0;
    --onscheme-4: 0 0 0;
    --onscheme-5: 0 0 0;
  }

  [data-theme="unity-auto"] .X-Arb a, [data-theme="unity-auto"] #homeButton {
    --tw-text-opacity: 1;
    color: rgb(234 88 12 / var(--tw-text-opacity) );
  }

  [data-theme="unity-auto"] #homeButton {
    outline-color: #ea580c;
  }

  [data-theme="unity-auto"] #homeButton:hover {
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity) );
    outline-color: #fb923c;
  }

  [data-theme="unity-auto"] .X-MenuBarButton {
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity) );
    --tw-text-opacity: 1;
  }

  [data-theme="unity-auto"] .X-MenuBarButton:hover {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity) );
  }

  [data-theme="unity-auto"] .X-Hide-Dark {
    height: 1rem;
    width: 1rem;
    display: inline;
  }
}

/*# sourceMappingURL=index.eb2b1caf.css.map */
